import React from 'react';

import Card from 'react-bootstrap/Card'
import Jumbotron from "react-bootstrap/Jumbotron";

import duelinteractive from './duelinteractive.svg';
import snAdvert from './images/snAdvertising.png';
import suAdvert from './images/suduAdvertising.png';
import apple from './Download_on_the_App_Store.svg';


import './App.css';


function App() {
    return (
        <div className="App">
            <Jumbotron fluid>
                <img src={duelinteractive} className="App-logo" alt="logo" />
            </Jumbotron>
            <header className="App-header">
                <p></p>
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={snAdvert} />
                    <Card.Body>
                        <Card.Title>Sports Nutz</Card.Title>
                        <Card.Text>
                            Sports crazy Squizzel the squirrel likes to match 3 or more of his favourite sports balls while protecting his food store of nutz. Help him hit his goals and see how far you can go.
                        </Card.Text>
                        <a href="https://apps.apple.com/us/app/sports-nutz/id1467447785"><img src={apple} alt='Apple'/></a>
                    </Card.Body>
                </Card>
                <p></p>

                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={suAdvert} />
                    <Card.Body>
                        <Card.Title>Sudoku Nutz</Card.Title>
                        <Card.Text>
                            Sudoku is a challenging logic-based, number-placement puzzle game. The objective is to fill a 9×9 grid with digits so that each column, each row, and each of the nine 3×3 sub grids all contain 1 to 9.
                        </Card.Text>
                        <a href="https://apps.apple.com/us/app/sports-nutz/id1477370616"><img src={apple} alt='Apple'/></a>
                    </Card.Body>
                </Card>
            </header>
        </div>
    );
}

export default App;
